<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     @reload="handleReload"
                     :btn_edit="false"
                     :btn_add="false"
                     :btn_delete="false"
                     :btn_review="true"
                     @review="handleReview"
        >
            <div class="pull-right d-flex" style="padding-top: 2px;">
                <el-button plain type="default" icon="el-icon-link" @click="$router.push('/host/')">用会议号码查报告</el-button>
            </div>
        </front-table>
        <!--编辑界面-->
        <el-dialog :visible.sync="view_mode" width="75%" @open="cleanData">
            <div slot="title" class="text-primary">
                ({{single.meeting_code}}) |
                {{single.course_number == single.course_name?'':(single.course_number+' | ')}}{{single.course_name}}
            </div>
            <div>
                <meeting-info :single="single"></meeting-info>

                <div class="mt-4"></div>
                <div class="">
                    <!--
                    <el-button size="medium" type="primary" icon="el-icon-question" onload="process.loading"
                               @click="searchMeetings" :loading="process.loading">点击查询课程
                    </el-button>
                    -->
                    <el-select ref="meetings" v-model="uuid" placeholder="请选择" size="medium" class="mr-3" filterable
                               no-data-text="未找到会议">
                        <el-option
                                v-for="item in meetings"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button size="medium" type="info" icon="el-icon-download" onload="process.loading"
                               @click="downloadReport" :loading="process.loading">下载报告
                    </el-button>
                </div>
                <div class="line"></div>
                <div v-show="show_table" ref="excel_table">
                    <table >
                        <tbody>
                        <tr v-for="(x,i) in table" :key="i">
                            <td v-for="(y,j) in x" :key="j" v-html="y"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="" @click="view_mode = false">确 定</el-button>
            </div>
        </el-dialog>
        <div class="clearfix"></div>
    </section>
</template>

<script>
    import Statistics from "../common/mixins/statistics-mixin";
    import FrontTable from "../components/front-table";
    import MeetingInfo from "../components/meeting-info";
    import FrontMixins from "../common/mixins/front-mixins";

    export default {
        components: {FrontTable, MeetingInfo},
        mixins: [FrontMixins, Statistics],
    }

</script>

<style lang="scss">

</style>
