<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     @reload="handleReload"
                     btn_review
                     @review="handleReview"
                     :btn_edit="false"
                     :btn_add="false"
                     :btn_delete="false"
        >
            <div>
                <div class="mx-3">
                    <el-cascader
                        class="mr-3"
                        v-model="search.area"
                        placeholder="请选择地区"
                        size="large"
                        :options="areas"
                        :props="{checkStrictly:true}"
                        @change="handleAreaChange"
                        style="width: 100%;"
                        clearable
                        filterable>
                    </el-cascader>
                </div>
                <el-divider></el-divider>
                <div class="m-3">
                    <el-switch @change="handleChange" v-model="search.use_date_range"
                               style="margin: 5px 10px 5px 0">  </el-switch> 选择{{search.use_date_range?"日期":"学期"}}
                </div>
                <el-divider></el-divider>
                <template v-if="search.use_date_range">
                    <div class="mt-3 mx-3 d-flex">
                        <el-button size="small" icon="el-icon-arrow-left" :disabled="!search.use_date_range"
                                   class="mr-2"
                                   @click="goDateChangeBack"></el-button>
                        <div class="text-muted" style="font-size: 75%;flex:1;text-align: center;line-height: 30px;">
                            选择日期
                        </div>
                        <el-button size="small" icon="el-icon-arrow-right" :disabled="!search.use_date_range"
                                   class="ml-2 pull-right" @click="goDateChangeForward"></el-button>
                    </div>
                    <div class="text-right mt-3 mx-3">
                        <el-date-picker size="large"
                                        :picker-options="{'firstDayOfWeek': 1}"
                                        @change="handleChange"
                                        :disabled="!search.use_date_range"
                                        v-model="search.search_date"
                                        type="date"
                                        value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                </template>
                <div class="m-3" v-else>
                    <el-select
                               clearable filterable size="medium"
                               v-model="search.extra['S']"
                               @change="handleChangeSemesterForExtra"
                               placeholder="请选择学期">
                        <el-option v-for="(x,id) in semester_options" :key="id" :value="id"
                                   :label="x.name+' ('+x.start_date+' - '+x.end_date+')'">
                            <span class="d-inline-block" style="width:200px;">{{ x.name }}</span>
                            <span class="text-success">{{ x.start_date }} - {{ x.end_date }}</span>
                        </el-option>
                    </el-select>
                </div>
            </div>
        </front-table>
        <!--编辑界面-->
        <div class="clearfix"></div>
        <lesson-view :single="single.course" :view_mode.sync="view_mode" :mobile_mode="true"></lesson-view>
    </section>
</template>

<script>
import Calendar from "@/common/mixins/calendar-mixin.js";
import FrontTable from "@/components/front-mobile-table";
import LessonView from "@/components/lesson-view";


export default {
    mixins: [Calendar],
    components: {LessonView, FrontTable},

    created() {
        this.loadArea(2, true);
    }
}
</script>

<style lang="scss">

</style>
