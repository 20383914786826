<template>
    <section class="favorite-mobile mt-3">
        <!--工具条-->
        <FullCalendar :locale="locale"
                      :first-day="1"
                      ref="fullCalendar"
                      defaultView="timeGridDay"
                      :plugins="calendarPlugins"
                      :now-indicator="true"
                      :all-day-slot="false"
                      min-time="08:00:00"
                      max-time="24:00:00"
                      slot-duration="00:30:00"
                      slot-label-interval="01:00:00"
                      height='auto'
                      :views="viewsSetting"
                      event-class-name="course-inner-text"
                      :events="loadEvents"
                      :event-time-format="eventTimeFormat"
                      :slot-label-format="slotLabelFormat"
                      @eventClick="handleEventClick"
                      :nav-links="true"
                      :header="{left:'prev,next',center:'title',right:'timeGridDay,timeGridWeek,dayGridMonth,listWeek'}"
        />

        <el-dialog :visible.sync="view_mode" v-mobile-dialog append-to-body>
            <div slot="title" class="text-primary">
                {{course.course_number === course.course_name?'':(course.course_number+' | ')}}{{course.course_name}}
            </div>
            <div>
                <meeting-info :single="single.course" mobile_mode="true"></meeting-info>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="" @click="view_mode = false">确 定</el-button>
            </div>
        </el-dialog>
    </section>

</template>

<script>
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import timeGridPlugin from '@fullcalendar/timegrid'
    import listPlugin from '@fullcalendar/list'
    import zhLocale from '@fullcalendar/core/locales/zh-cn';
    import MeetingInfo from "../../components/meeting-info";

    export default {
        name: "dashboard",
        components: {
            MeetingInfo,
            FullCalendar // make the <FullCalendar> tag available
        },
        data() {
            return {
                calendarPlugins: [dayGridPlugin, timeGridPlugin, listPlugin],
                locale: zhLocale,
                slotLabelFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                },
                eventTimeFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                },
                data: [],
                single: {},
                view_mode: false,
                viewsSetting:{
                    dayGrid: {
                        // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
                        titleFormat:{month:'2-digit'},
                    },
                    timeDayGrid: {
                        // options apply to timeGridWeek and timeGridDay views
                        titleFormat:{month:'2-digit',day:'2-digit'}
                    },
                    week: {
                        // options apply to dayGridWeek and timeGridWeek views
                        titleFormat:{day:'2-digit'},
                    },
                    day: {
                        // options apply to dayGridDay and timeGridDay views
                        titleFormat:{month:'2-digit',day:'2-digit'}
                    }
                }
            }
        },
        computed: {
            course() {
                return this.single.course ? this.single.course : {};
            },
        },
        methods: {
            reload() {
                const month = 1;
                this.$api('User.Calendar.My', {month: month}, 1).then((res) => {
                    this.evnets = res.data;
                });
            },
            loadEvents(fetchInfo, successCallback) {
                this.$api('User.Calendar.My', {
                    start: fetchInfo.start.Format('yyyy-MM-dd'),
                    end: fetchInfo.end.Format('yyyy-MM-dd')
                }, 1).then((res) => {
                    this.data = res.data.data;
                    successCallback(res.data.data);
                });
            },
            handleEventClick(eventInfo) {
                const id = eventInfo.event.id;
                this.$api('User.Calendar.get', {id: id}, 1).then((res) => {
                    this.single = res.data.data;
                    this.view_mode = true;
                });
            },
            removeFavorite() {
                this.$api('User.Calendar.remove', this.single, 1).then(() => {
                    this.view_mode = false;
                    this.$notice.success(this.course.course_name + ' 已经从收藏中移除!');
                    let api = this.$refs.fullCalendar.getApi();
                    const src = api.getEventSources();
                    src.forEach(function (x) {
                        x.refetch();
                    });
                });
            },
            navDay(date, jsEvent){
                console.log('day', date.toISOString());
                console.log('coords', jsEvent.pageX, jsEvent.pageY);
            },
            navWeek(weekStart, jsEvent){
                console.log('day', weekStart.toISOString());
                console.log('coords', jsEvent.pageX, jsEvent.pageY);
            }
        },
        mounted() {

        }
    }
</script>

<style lang="scss">
    @import '~@fullcalendar/core/main.css';
    @import '~@fullcalendar/daygrid/main.css';
    @import '~@fullcalendar/timegrid/main.css';
    @import "~@fullcalendar/list/main.css";

    .favorite-mobile {

        .fc-center {
            h2 {
                font-size: 18px;
                color:#009688;
            }
        }
        .course-inner-text {
            color: white;
            font-size: 14px;
            background-color: #009688;
            transition: all 0.4s ease;


            .fc-time {
                color: white;
                font-size: 13px;
                font-weight: bold;
            }

            &:hover {
                color: whitesmoke;
                cursor: pointer;
                background-color: #0288d1;
                border-color: #0288d1;
                box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.5);
                transition: all 0.4s ease;

            }

            &.fc-list-item {
                background-color: transparent;
                color: black;

                &:hover {
                    box-shadow: none;
                }
            }
        }


    }

</style>
